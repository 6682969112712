<template>
    <div id="order-column-chooser" :class="`dropdown d-lg-inline-block mb-1 mb-lg-0 ${classes}`">
        <button class="btn btn-secondary dropdown-toggle  btn btn-success btn-sm ms-1 d-block d-lg-inline-block mt-lg-0 mb-lg-0 mt-2" type="button" id="columnChooser" data-bs-toggle="dropdown" aria-expanded="false">
            Column Chooser
        </button>
        <fieldset>
            <ul class="dropdown-menu text-start" aria-labelledby="columnChooser">
                <li class="dropdown-item ">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="image" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_Image']" :true-value="1" :false-value="0">
                        <label> Image</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="item_no" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_ItemNo']" :true-value="1" :false-value="0">
                        <label> Item #</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="price_code" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_PriceCode']" :true-value="1" :false-value="0">
                        <label> Price Code</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="healthy" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_Healthy']" :true-value="1" :false-value="0">
                        <label> Healthy</label>
                    </div>
                </li>
                <li class="dropdown-item" v-if="$store.state.localStorage.user['Admin']">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="msrp" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_MSRP']" :true-value="1" :false-value="0">
                        <label> MSRP</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_ShelfLife']" :true-value="1" :false-value="0">
                        <label> Shelf Life</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_UpcCode']" :true-value="1" :false-value="0">
                        <label> UPC Code</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="removed_from_menu" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_RemoveFromMenu']" :true-value="1" :false-value="0">
                        <label> Removed from Menu</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="user_defined" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_UserDef']" :true-value="1" :false-value="0">
                        <label> User Defined</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="tray_size" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_TraySize']" :true-value="1" :false-value="0">
                        <label> Tray Size</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="pull_code" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_PullCode']" :true-value="1" :false-value="0">
                        <label> Label Date</label>
                    </div>
                </li>
                <li class="dropdown-item" v-if="$store.state.localStorage.user['Admin']">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="msrp_code" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_MSRPCode']" :true-value="1" :false-value="0">
                        <label> MSRP Code</label>
                    </div>
                </li>
                <li class="dropdown-item" v-if="$store.state.localStorage.user['Admin']">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="base_cost" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_BaseCost']" :true-value="1" :false-value="0">
                        <label> Base Cost</label>
                    </div>
                </li>
                <li class="dropdown-item" v-if="$store.state.localStorage.user['Admin']">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="extend" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_Extended']" :true-value="1" :false-value="0">
                        <label> Extend</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="hist_ttl" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_HistTot']" :true-value="1" :false-value="0">
                        <label> Hist Ttl</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="Hist1" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_Hist1']" :true-value="1" :false-value="0">
                        <label> H1</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="Hist2" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_Hist2']" :true-value="1" :false-value="0">
                        <label> H2</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="Hist3" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_Hist3']" :true-value="1" :false-value="0">
                        <label> H3</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="Hist4" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_Hist4']" :true-value="1" :false-value="0">
                        <label> H4</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="Hist5" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_Hist5']" :true-value="1" :false-value="0">
                        <label> H5</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="Hist6" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_Hist6']" :true-value="1" :false-value="0">
                        <label> H6</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="Hist7" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_Hist7']" :true-value="1" :false-value="0">
                        <label> H7</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="Hist8" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_Hist8']" :true-value="1" :false-value="0">
                        <label> H8</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="Hist9" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_Hist9']" :true-value="1" :false-value="0">
                        <label> H9</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="Hist10" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_Hist10']" :true-value="1" :false-value="0">
                        <label> H10</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="gluten_free" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_GlutenFree']" :true-value="1" :false-value="0">
                        <label> Gluten Free</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="vegetarian" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_Vegetarian']" :true-value="1" :false-value="0">
                        <label> Vegetarian</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="vegan" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_Vegan']" :true-value="1" :false-value="0">
                        <label> Vegan</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="lenten" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_Lenten']" :true-value="1" :false-value="0">
                        <label> Lenten</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="pork" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['Order_Vis_Pork']" :true-value="1" :false-value="0">
                        <label for="pork"> No Pork</label>
                    </div>
                </li>
            </ul>
        </fieldset>
    </div>
  </template>
  <script>

  import { Field, Form, ErrorMessage, defineRule } from 'vee-validate';
  defineRule('required', (value, [target], ctx) => {
    if(!value) return 'Value is required';
    return true;
  });

  export default {
    name: 'ColumnChooser',
    components:{Field ,Form, ErrorMessage},
    props: ['classes'],
    watch:{
    },
    computed:{
    },
    data(){
        return {}
    },
    async mounted(){
        $('#order-column-chooser.dropdown .dropdown-menu').on('click',  (e) => {
            e.stopPropagation();
        });
    },
    methods:{
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
    .field-error{
      font-size: 0.75rem;
    }
  </style>
  