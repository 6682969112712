<template>
    <nav class="pt-0 ps-0 pe-0">
        <a href="/dashboard/" class="btn btn-primary d-block text-center mt-1 mb-1">Dashboard</a>
        <a href="/delivery-instructions/" :class="`btn btn-primary d-block text-center mt-1 mb-1 ${+$store.state.localStorage.user['Responsive View']==1?'d-none d-lg-block':'d-block'}`" v-if="$root.getCustomerNumber()!=''&&$root.isKfEmployee()" data-intro="View and Edit Delivery Instructions">Delivery Instructions</a>

        <a href="/locations/" class="btn btn-primary d-block text-center mt-1 mb-1" v-if="$root.getCustomerNumber()!=''"  data-intro="View and Edit Delivery Locations">Locations</a>

        <a href="/items/" :class="`btn btn-primary text-center mt-1 mb-1 ${+$store.state.localStorage.user['Responsive View']==1?'d-none d-lg-block':'d-block'}`" v-if="$store.state.localStorage.user['item List Change']&&$store.state.localStorage.user['Admin']" data-intro="View and edit items">Item Maintenance</a>

        <a href="/OrderSummary/" :class="`btn btn-primary ${+$store.state.localStorage.user['Responsive View']==1?'d-none d-lg-block':'d-block'} text-center mt-1 mb-1`" v-if="
            ($root.getCustomerNumber()!='')
        " data-intro="View, Edit, and Create Orders">Order Summary</a>

        <a href="/customers/" class="btn btn-primary d-block text-center mt-1 mb-1 " v-if="$store.state.localStorage.user.Impersonate&&$store.state.localStorage.user.Admin&&!$store.state.localStorage.customer&&!$store.state.sessionStorage.customer" data-intro="View, Edit and Create Customers">Customers</a>
        
        <a :href="`/customers/${$root.getCustomerNumber()}/users/`" class="btn btn-primary d-block text-center mt-1 mb-1" v-if="$root.getCustomerNumber()!=''&&$store.state.localStorage.user['Edit Users']" data-intro="View, Edit and Create Users">Users</a>
        <a :href="`/users/`" class="btn btn-primary d-block text-center mt-1 mb-1" v-if="$root.getCustomerNumber()==''&&$store.state.localStorage.user['Edit Users']" data-intro="View, Edit and Create Users">Users</a>
        
        <a href="/waste/" class="btn btn-primary d-block text-center mt-1 mb-1" v-if="($store.state.localStorage.user['Waste System'] || $store.state.localStorage.user.Admin) && $root.getCustomerNumber()!=''" data-intro="Scan waste">Waste</a>

        <a href="/reports/" :class="`btn btn-primary  text-center mt-1 mb-1 ${+$store.state.localStorage.user['Responsive View']==1?'d-none d-lg-block':'d-block'}`" v-if="($store.state.localStorage.user.Reports || $store.state.localStorage.user['Invoice Reports'] || $store.state.localStorage.user.Admin) && this.$root.getCustomerNumber()!=''" data-intro="Generate reports">Reports</a>

        <a href="/orderaddcut/" :class="`btn btn-primary d-block text-center mt-1 mb-1  ${+$store.state.localStorage.user['Responsive View']==1?'d-none d-lg-block':'d-block'}`" v-if="($root.getCustomerNumber()==''&&$store.state.localStorage.user.Admin)" data-intro="Create, Modify and Delete Add/Cut times">Order Add/Cut</a>

        <a href="/update-password/" class="btn btn-primary d-block text-center mt-1 mb-1" v-if="$store.state.localStorage.user.Admin" data-intro="Change your account password">Change Password</a>

        <a target="_blank" href="https://portal2.ftnirdc.com/en/271528" class="btn btn-primary d-block text-center mt-1 mb-1" v-if="$store.state.localStorage.user.Admin&&$store.state.localStorage.user.pay_bill==1" data-intro="Pay bill online.">Pay Bill</a>

        <a href="/trainingvideos/" class="btn btn-primary d-block text-center mt-1 mb-1" v-if="$store.state.localStorage.user.Admin" data-intro="Watch training videos.">Training Videos</a>

        <a href="/enterprise-upload/" :class="`btn btn-primary ${+$store.state.localStorage.user['Responsive View']==1?'d-none d-lg-block':'d-block'} text-center mt-1 mb-1`" v-if="$store.state.localStorage.user['APIIntegrationPermission'] " data-intro="API integration for your own platform.">Enterprise Upload</a>

        <a href="/roundys-upload/" :class="`btn btn-primary ${+$store.state.localStorage.user['Responsive View']==1?'d-none d-lg-block':'d-block'} text-center mt-1 mb-1`" v-if="($root.getCustomerNumber()==''&&$store.state.localStorage.user.Admin)" data-intro="API integration for your own platform.">Roundys Upload</a>

        <!-- <a href="/integration/" :class="`btn btn-primary ${+$store.state.localStorage.user['Responsive View']==1?'d-none d-lg-block':'d-block'} text-center mt-1 mb-1`" v-if="$store.state.localStorage.user['APIIntegrationPermission'] " data-intro="API integration for your own platform.">Integration</a> -->
        
        <a href="/subscribe-to-text-alerts/" class="btn btn-primary d-block text-center mt-1 mb-1" data-intro="Subscribe to Text Alerts.">Subscribe to Text Alerts</a>

        <!-- user is impersonating, let them go back to super admin -->
        <a class="btn btn-primary d-block text-center mt-3 mb-1" v-if="$store.state.sessionStorage.customer" @click="backToSuperAdmin">Back to Super Admin Account</a>

        <a class="btn btn-secondary d-block text-center mt-3 mb-1" @click="logOut">Logout</a>
    </nav>
  </template>
  
  <script>
  import Help from '@/components/Help.vue'

  export default {
    name: 'Links',
    components:{Help},
    props: {
    },
    watch:{
    },
    data(){
        return {}
    },
    async mounted(){
       
    },
    methods:{
        backToSuperAdmin(){
            sessionStorage.clear();
            localStorage.removeItem('shipTos');
            localStorage.removeItem('shipTo');
            localStorage.removeItem('openInvoices');
            localStorage.removeItem('account_status');
            localStorage.removeItem('balance');
            location.href = '/customers/';
        },
        logOut(){
            localStorage.clear();
            sessionStorage.clear();
            location.reload();
        }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
    .table-wrapper{
        width: 100%;
        overflow-x:scroll;
        overflow-y:scroll;
        height:650px;
        table{
            *{
            }
        }
    }
    
  </style>
  