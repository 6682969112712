<template>
    <tbody >
        <tr :class="`
            ${$store.state.localStorage.user['Order_Vis_Image']?'has-order-image':''}
            ${$store.state.localStorage.user['Order_Vis_ItemNo']?'has-order-itemno':''}
            ${$store.state.localStorage.user['Order_Vis_PriceCode']?'has-order-pricecode':''}
            ${$store.state.localStorage.user['Order_Vis_Healthy']?'has-order-healthy':''}
            ${$store.state.localStorage.user['Order_Vis_MSRP']?'has-order-msrp':''}
            ${$store.state.localStorage.user['Order_Vis_UpcCode']?'has-order-upccode':''}
            ${$store.state.localStorage.user['Order_Vis_RemoveFromMenu']?'has-order-removefrommenu':''}
            ${$store.state.localStorage.user['Order_Vis_UserDef']?'has-order-userdef':''}
            ${$store.state.localStorage.user['Order_Vis_TraySize']?'has-order-traysize':''}
            ${$store.state.localStorage.user['Order_Vis_ShelfLife']?'has-order-shelflife':''}
            ${$store.state.localStorage.user['Order_Vis_PullCode']?'has-order-pullcode':''}
            ${$store.state.localStorage.user['Order_Vis_MSRPCode']?'has-order-msrpcode':''}
            ${$store.state.localStorage.user['Order_Vis_BaseCost']?'has-order-basecost':''}
            ${$store.state.localStorage.user['Order_Vis_Extended']?'has-order-extended':''}
            ${$store.state.localStorage.user['Order_Vis_HistTot']?'has-order-histtot':''}
            ${$store.state.localStorage.user['Order_Vis_GlutenFree']?'has-order-glutenfree':''}
            ${$store.state.localStorage.user['Order_Vis_Vegetarian']?'has-order-vegetarian':''}
            ${$store.state.localStorage.user['Order_Vis_Vegan']?'has-order-vegan':''}
            ${$store.state.localStorage.user['Order_Vis_Lenten']?'has-order-lenten':''}
            ${$store.state.localStorage.user['Order_Vis_Pork']?'has-order-lenten':''}
            ${$store.state.localStorage.user['Order_Vis_Hist1']?'has-hist1':''}
            ${$store.state.localStorage.user['Order_Vis_Hist2']?'has-hist2':''}
            ${$store.state.localStorage.user['Order_Vis_Hist3']?'has-hist3':''}
            ${$store.state.localStorage.user['Order_Vis_Hist4']?'has-hist4':''}
            ${$store.state.localStorage.user['Order_Vis_Hist5']?'has-hist5':''}
            ${$store.state.localStorage.user['Order_Vis_Hist6']?'has-hist6':''}
            ${$store.state.localStorage.user['Order_Vis_Hist7']?'has-hist7':''}
            ${$store.state.localStorage.user['Order_Vis_Hist8']?'has-hist8':''}
            ${$store.state.localStorage.user['Order_Vis_Hist9']?'has-hist9':''}
            ${$store.state.localStorage.user['Order_Vis_Hist10']?'has-hist10':''}
        `" v-show="item['itemno'].toLowerCase().startsWith(search['itemno'].toLowerCase().trim())&&
                    new RegExp(search['itmdesc'].toLowerCase().trim(), 'gi').test(item['itmdesc'].toLowerCase())&&  
                    item['pricecode'].toLowerCase().startsWith(search['pricecode'].toLowerCase().trim())&&
                    item['msrp'].toLowerCase().startsWith(search['msrp'].toLowerCase().trim())&&
                    item['UPC'].toLowerCase().startsWith(search['UPC'].toLowerCase().trim())&&
                    item['userdef'].toLowerCase().startsWith(search['userdef'].toLowerCase().trim())&&
                    item['tray'].toString().toLowerCase().startsWith(search['tray'].toLowerCase().trim())&&
                    item['life'].toString().toLowerCase().startsWith(search['life'].toLowerCase().trim())&&
                    (search['qty']?item['qty']!='':(item['qty']!=''||item['qty']==''))&&
                    (search['healthy']?item['healthy']!='':(item['healthy']!=''||item['healthy']==''))&&
                    item['pullcode'].toString().toLowerCase().startsWith(search['pullcode'].toLowerCase().trim())&&
                    item['msrpcode'].toString().toLowerCase().startsWith(search['msrpcode'].toLowerCase().trim())&&
                    item['basecost'].toString().toLowerCase().startsWith(search['basecost'].toLowerCase().trim())&&
                    item['EXTENDED'].toString().toLowerCase().startsWith(search['EXTENDED'].toLowerCase().trim())&&
                    item['ttl'].toString().toLowerCase().startsWith(search['ttl'].toLowerCase().trim())&&
                    item['H1'].toString().toLowerCase().startsWith(search['H1'].toLowerCase().trim())&&
                    item['H2'].toString().toLowerCase().startsWith(search['H2'].toLowerCase().trim())&&
                    item['H3'].toString().toLowerCase().startsWith(search['H3'].toLowerCase().trim())&&
                    item['H4'].toString().toLowerCase().startsWith(search['H4'].toLowerCase().trim())&&
                    item['H5'].toString().toLowerCase().startsWith(search['H5'].toLowerCase().trim())&&
                    item['H6'].toString().toLowerCase().startsWith(search['H6'].toLowerCase().trim())&&
                    item['H7'].toString().toLowerCase().startsWith(search['H7'].toLowerCase().trim())&&
                    item['H8'].toString().toLowerCase().startsWith(search['H8'].toLowerCase().trim())&&
                    item['H9'].toString().toLowerCase().startsWith(search['H9'].toLowerCase().trim())&&
                    item['H10'].toString().toLowerCase().startsWith(search['H10'].toLowerCase().trim())&&
                    (search['glutenfree']?item['glutenfree']!='':(item['glutenfree']!=''||item['glutenfree']==''))&&
                    (search['vegetarian']?item['vegetarian']!='':(item['vegetarian']!=''||item['vegetarian']==''))&&
                    (search['vegan']?item['vegan']!='':(item['vegan']!=''||item['vegan']==''))&&
                    (search['lenten']?item['lenten']!='':(item['lenten']!=''||item['lenten']==''))&&
                    (search['pork']?item['pork']!='':(item['pork']!=''||item['pork']==''))&&
                    (search['removed']?item['removed']!='':(item['removed']!=''||item['removed']==''))" v-for="(item,i) in group" :key="i"
        >
            <td :class="`text-start text-lg-center image`" width="80" v-if="$store.state.localStorage.user['Order_Vis_Image']">
                <a tabindex="-1" target="_blank" :href="`https://kitchenfreshfoods.com/product-images/${item['itemno']}.jpg`" :download="`${item['itemno']}.jpg`"><img :src="`https://kitchenfreshfoods.com/product-images/thumbnails/${item['itemno']}.jpg`" width="70"></a>
            </td>
            <td class="text-start text-lg-center itemno" width="70" v-if="$store.state.localStorage.user['Order_Vis_ItemNo']">
                <a tabindex="-1" target="_blank" :href="`${$store.state.api}/pdfs/iteminfo.php?item_no=${item['itemno']}`">({{item['itemno']}})</a>
            </td>
            <td class="text-start text-lg-center desc" width="160">
                <a tabindex="-1" target="_blank" :href="`${$store.state.api}/pdfs/iteminfo.php?item_no=${item['itemno']}`">{{item['itmdesc']}} ({{item['itemno']}})</a>
            </td>
            <td class="text-start text-lg-center pricecode" width="100" v-if="$store.state.localStorage.user['Order_Vis_PriceCode']" >
                {{item['pricecode']}}
            </td>
            <td width="100" class=" input-group-sm text-start text-lg-center qty">
                <input title="" autocomplete="off" autosuggest="off" :tabindex="310+(300*indx)+i" maxlength="5" class="form-control qty-field w-100 ms-auto me-auto" type="text" :data-item="JSON.stringify(item)" v-model="item['qty']"  @input="item['EXTENDED'] = (+(item['basecost'])*+item.qty).toFixed(2);" style="max-width: 80px;" v-if="($root.isKfEmployee()&&(past_add_cut==true||past_add_cut==false)&&header.ll_inprod==false)"
                    @keydown="nextPriceCode"    
                    @focus="$event.target.select(); showPopOver($event)" 
                    @blur="$emit('saveItem', item); removePopOver($event)" 
                    :data-bs-toggle="`${$store.state.localStorage.user['Order Entry Use Popovers']==1?'popover':'no-popover'}`" :title="`<a href='' class='text-black text-decoration-none'>${item.itmdesc}</a>`"
                >
                <input title="" autocomplete="off" autosuggest="off" :tabindex="310+(300*indx)+i" maxlength="5" class="form-control qty-field w-100 ms-auto me-auto" type="text"  :data-item="JSON.stringify(item)" v-model="item['qty']"  @input="item['EXTENDED'] = (+(item['basecost'])*+item.qty).toFixed(2);" style="max-width: 80px;" v-else-if="($root.isKfEmployee()==false&&(past_add_cut==false)&&header.ll_inprod==false)"
                    @keydown="nextPriceCode"    
                    @focus="$event.target.select(); showPopOver($event)" 
                    @blur="$emit('saveItem', item); removePopOver($event)" 
                    :data-bs-toggle="`${$store.state.localStorage.user['Order Entry Use Popovers']==1?'popover':'no-popover'}`" :title="`<a href='' class='text-black text-decoration-none'>${item.itmdesc}</a>`">
                <span v-else>{{ item['qty'] }}</span>
            </td>
            <td width="80" class="text-start text-lg-center healthy" v-if="$store.state.localStorage.user['Order_Vis_Healthy']" v-html="isHealthy(item)"></td>
            <td width="65" class="text-start text-lg-center msrp" v-if="$store.state.localStorage.user['Order_Vis_MSRP']&&$store.state.localStorage.user['Admin']">
                {{parseFloat(item['msrp']).toFixed(2)}}
            </td>
            <td width="120" class="text-start text-lg-center upccode" v-if="$store.state.localStorage.user['Order_Vis_UpcCode']">
                {{item['UPC']}}
            </td>
            <td width="90" class="text-start text-lg-center removefrommenu" v-if="$store.state.localStorage.user['Menu Change']&&$store.state.localStorage.user['Order_Vis_RemoveFromMenu']"> 
                <div class="form-check form-switch">
                    <input  class="form-check-input" autocomplete="off" autosuggest="off" tabindex="-1" type="checkbox" v-model="item['removed']" :true-value="1" :false-value="0"  :disabled="header.ll_inprod" @change="$emit('removeFromMenu',item)" />
                </div>
            </td>
            <td width="80" class="text-start text-lg-center userdef" v-if="$store.state.localStorage.user['Order_Vis_UserDef']">
                {{item['userdef']}}
            </td>
            <td width="80" class="text-start text-lg-center traysize" v-if="$store.state.localStorage.user['Order_Vis_TraySize']">
                {{parseFloat(item['tray']).toFixed(0)}}
            </td>
            <td width="80" class="text-start text-lg-center shelflife" v-if="$store.state.localStorage.user['Order_Vis_ShelfLife']">
                {{Number(item['life']).toFixed(0)}}
            </td>
            <td width="80" class="text-start text-lg-center pullcode" v-if="$store.state.localStorage.user['Order_Vis_PullCode']">
                {{$root.moment(item['pullcode'], 'MM/DD/YYYY').format('MM/DD')}}
            </td>
            <td width="100" class="text-start text-lg-center msrpcode" v-if="$store.state.localStorage.user['Order_Vis_MSRPCode']&&$store.state.localStorage.user['Admin']">
                {{item['msrpcode']}}
            </td>
            <td width="80" class="text-start text-lg-center basecost" v-if="$store.state.localStorage.user['Order_Vis_BaseCost']&&$store.state.localStorage.user['Admin']">
                {{(parseFloat(item['basecost'])).toFixed(2)}}
                <span class="d-none">{{ item['EXTENDED'] = (+(item['basecost'])*+item.qty).toFixed(2) }}</span>
            </td>
            <td width="80" class="text-start text-lg-center extended" v-if="$store.state.localStorage.user['Order_Vis_Extended']&&$store.state.localStorage.user['Admin']">
                {{item['EXTENDED']}}
            </td>
            <td width="50" class="text-start text-lg-center histtotal" v-if="$store.state.localStorage.user['Order_Vis_HistTot']">
                {{item['ttl']}}
            </td>
            <td  width="45" class="text-start text-lg-center hist1" v-if="$store.state.localStorage.user['Order_Vis_Hist1']">
                {{item['H1']}}
            </td>
            <td  width="45" class="text-start text-lg-center hist2" v-if="$store.state.localStorage.user['Order_Vis_Hist2']">
                {{item['H2']}}
            </td>
            <td  width="45" class="text-start text-lg-center hist3"  v-if="$store.state.localStorage.user['Order_Vis_Hist3']">
                {{item['H3']}}
            </td>
            <td  width="45" class="text-start text-lg-center hist4"  v-if="$store.state.localStorage.user['Order_Vis_Hist4']">
                {{item['H4']}}
            </td>
            <td  width="45" class="text-start text-lg-center hist5" v-if="$store.state.localStorage.user['Order_Vis_Hist5']">
                {{item['H5']}}
            </td>
            <td  width="45" class="text-start text-lg-center hist6" v-if="$store.state.localStorage.user['Order_Vis_Hist6']">
                {{item['H6']}}
            </td>
            <td   width="45" class="text-start text-lg-center hist7" v-if="$store.state.localStorage.user['Order_Vis_Hist7']">
                {{item['H7']}}
            </td>
            <td  width="45" class="text-start text-lg-center hist8" v-if="$store.state.localStorage.user['Order_Vis_Hist8']">
                {{item['H8']}}
            </td>
            <td  width="45" class="text-start text-lg-center hist9"  v-if="$store.state.localStorage.user['Order_Vis_Hist9']">
                {{item['H9']}}
            </td>
            <td  width="45" class="text-start text-lg-center hist10" v-if="$store.state.localStorage.user['Order_Vis_Hist10']">
                {{item['H10']}}
            </td>
            <td width="80" class="text-start text-lg-center glutenfree" v-if="$store.state.localStorage.user['Order_Vis_GlutenFree']" v-html="isGlutenFree(item)"></td>
            <td width="80" class="text-start text-lg-center vegetarian" v-if="$store.state.localStorage.user['Order_Vis_Vegetarian']" v-html="isVegetarian(item)"></td>
            <td width="80" class="text-start text-lg-center vegan" v-if="$store.state.localStorage.user['Order_Vis_Vegan']" v-html="isVegan(item)"></td>
            <td width="80" class="text-start text-lg-center lenten" v-if="$store.state.localStorage.user['Order_Vis_Lenten']" v-html="isLenten(item)"></td>
            <td width="80" class="text-start text-lg-center lenten" v-if="$store.state.localStorage.user['Order_Vis_Pork']" v-html="isPork(item)"></td>
        </tr>
    </tbody>
</template>
  <script>

  export default {
    name: 'TableBody',
    props:['group', 'header', 'indx','search','past_add_cut'],
    components: {},
    data(){
        return {
            _:_,
            popover:null
        }
    },
    watch:{
        filter:{
            deep:true,
            handler(newVal){
            },
        },
    },
    async mounted(){
    },
    emits:{
        removeFromMenu:null,
        saveItem:null,
        keydown:null
    },
    methods:{
        async nextPriceCode(e){
            this.$emit('keydown', e);
            
        },
        action(e){
        
        },
        async keyDownQty(e,item){
            let tabIndex = parseInt($(e.target).attr('tab-index'));
            if($(e.target).parents('tr').next().find('input[type="text"][tab-index="'+(tabIndex+1)+'"][data-bs-toggle*="popover"]').length){
                let $next = $(e.target).parents('tr').next().find('input[type="text"][tab-index="'+(tabIndex+1)+'"][data-bs-toggle*="popover"]');
                $next.focus();
            }
        },
        isVegan(item){
            if(item.vegan) return "<svg width='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><style>path{fill:var(--bs-green);}</style><path d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/></svg>";
            else  return '';
        },
        isVegetarian(item){
            if(item.vegetarian) return "<svg width='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><style>path{fill:var(--bs-green);}</style><path d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/></svg>";
            else  return '';
        },
        isGlutenFree(item){
            if(item.glutenfree) return "<svg width='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><style>path{fill:var(--bs-green);}</style><path d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/></svg>";
            else  return '';
        },
        isHealthy(item){
            if(item.healthy) return "<svg width='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><style>path{fill:var(--bs-green);}</style><path d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/></svg>";
            else  return '';
        },
        isRestricted(item){
            if(item['Restricted Menu Item']) return "<svg width='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><style>path{fill:var(--bs-green);}</style><path d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/></svg>";
            else  return '';
        },
        isLenten(item){
            if(item['lenten']) return "<svg width='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><style>path{fill:var(--bs-green);}</style><path d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/></svg>";
            else  return '';
        },
        isPork(item){
            if(item['pork']) return "<svg width='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><style>path{fill:var(--bs-green);}</style><path d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/></svg>";
            else  return '';
        },
        showPopOver(e){
            let el = e.target;
            // e.target.scrollIntoView();
            // console.log($(e.target).offset())
            // setTimeout(()=>{
            //     // window.scrollTo(0,$(el).offset().top);
            //     $('#main-app-container>.row>[class*=col]:nth-of-type(2)').animate({
            //         scrollTop: $(e.target).offset().top
            //     }, 1000); // Adjust the duration as needed
            // }, 10);
            
            if(el.getAttribute('data-bs-toggle')=='popover'){
                let item = (el.getAttribute('data-item'));
                item = JSON.parse(item);
                this.popover = new bootstrap.Popover(el, {
                    html:true,
                    'content':`
                        <div class='text-start text-lg-center'><img src='https://kitchenfreshfoods.com/product-images/${item.itemno}.jpg' class='mw-100 mh-100'></div>
                        <div class='mt-1'>${item.sales_description_1}${item.sales_description_2}${item.sales_description_3}${item.sales_description_4}</div>
                        <div class='mt-2'>
                            <div class='mb-1'>Order History: ${this.$store.state.localStorage.user['Order Entry History Display']}</div>    
                            <div class='mb-1'>
                                <div class='row'>
                                    <div class='col-4 border-start border-bottom border-end'>H1: ${item['H1']}</div>
                                    <div class='col-4 border-start border-bottom border-end'>H2: ${item['H2']}</div>
                                    <div class='col-4 border-start border-bottom border-end'>H3: ${item['H3']}</div>
                                    <div class='col-4 border-start border-bottom border-end'>H4: ${item['H4']}</div>
                                    <div class='col-4 border-start border-bottom border-end'>H5: ${item['H5']}</div>
                                    <div class='col-4 border-start border-bottom border-end'>H6: ${item['H6']}</div>
                                    <div class='col-4 border-start border-bottom border-end'>H7: ${item['H7']}</div>
                                    <div class='col-4 border-start border-bottom border-end'>H8: ${item['H8']}</div>
                                    <div class='col-4 border-start border-bottom border-end'>H9: ${item['H9']}</div>
                                    <div class='col-4 border-start border-bottom border-end'>H10: ${item['H10']}</div>
                                    <div class='col-12 mt-2'><b>TTL:</b> ${item['ttl']}</div>
                                    <div class='col-12'>
                                        <b>Wst%:</b> ${item['waste_perc']}%
                                    </div>    
                                </div>
                            </div>    
                        </div>
                    `
                });
                this.popover.show();
            }
        },
        removePopOver(e){
            if(this.popover) this.popover.hide();
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
    
  </style>
  