<template>
    <thead class="position-relative" id="tableFilters">
        <tr>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_Image']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>Image</small></span>
            </th>
            <th width="70" v-if="$store.state.localStorage.user['Order_Vis_ItemNo']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>Item</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); filter.by='number';  filter.orderBy='itemno'" :class="`${(filter.orderBy=='itemno'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='itemno'?'':'rotate')}`" />
            </th>
            <th width="160" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>Description</small></span>
            </th>
            <th width="100" v-if="$store.state.localStorage.user['Order_Vis_PriceCode']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>Price Code</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='pricecode'" :class="`${(filter.orderBy=='pricecode'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='pricecode'?'':'rotate')}`" />
            </th>
            <th width="100" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>Order Qty</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='qty'" :class="`${(filter.orderBy=='qty'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='qty'?'':'rotate')}`" />
            </th>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_Healthy']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>Healthy</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='healthy'" :class="`${(filter.orderBy=='healthy'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='healthy'?'':'rotate')}`" />
            </th>
            <th width="65" v-if="$store.state.localStorage.user['Order_Vis_MSRP']&&$store.state.localStorage.user['Admin']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>MSRP</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='msrp'" :class="`${(filter.orderBy=='msrp'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='msrp'?'':'rotate')}`" />
            </th>
            <th width="120" v-if="$store.state.localStorage.user['Order_Vis_UpcCode']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>UPC Code</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='UPC'" :class="`${(filter.orderBy=='UPC'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='UPC'?'':'rotate')}`" />
            </th>
            <th width="90" class="text-white" style="background-color: var(--bs-gray-700);" v-if="$store.state.localStorage.user['Menu Change']&&$store.state.localStorage.user['Order_Vis_RemoveFromMenu']">
              <span><small>Removed from Menu</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='removed'" :class="`${(filter.orderBy=='removed'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='removed'?'':'rotate')}`" />
            </th>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_UserDef']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>User Defined</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='userdef'" :class="`${(filter.orderBy=='userdef'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='userdef'?'':'rotate')}`" />
            </th>
            <th width="80"  v-if="$store.state.localStorage.user['Order_Vis_TraySize']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>Tray Size</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='tray'" :class="`${(filter.orderBy=='tray'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='tray'?'':'rotate')}`" />
            </th>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_ShelfLife']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>Shelf Life</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='life'" :class="`${(filter.orderBy=='life'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='life'?'':'rotate')}`" />
            </th>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_PullCode']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>Label Date</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='pullcode'" :class="`${(filter.orderBy=='pullcode'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='pullcode'?'':'rotate')}`" />
            </th>
            <th width="100" v-if="$store.state.localStorage.user['Order_Vis_MSRPCode']&&$store.state.localStorage.user['Admin']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>MSRP Code</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='msrp'" :class="`${(filter.orderBy=='msrp'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='msrp'?'':'rotate')}`" />
            </th>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_BaseCost']&&$store.state.localStorage.user['Admin']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>Base Cost</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='basecost'" :class="`${(filter.orderBy=='basecost'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='basecost'?'':'rotate')}`" />
            </th>
            <!-- <th width="80" v-if="$store.state.localStorage.user['Order_Vis_BaseCode']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>Base Code</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='basecode'" :class="`${(filter.orderBy=='basecode'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='basecode'?'':'rotate')}`" />
            </th> -->
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_Extended']&&$store.state.localStorage.user['Admin']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>Extend</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='EXTENDED'" :class="`${(filter.orderBy=='EXTENDED'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='EXTENDED'?'':'rotate')}`" />
            </th>
            <th width="50"  v-if="$store.state.localStorage.user['Order_Vis_HistTot']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>Hist Ttl</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='ttl'" :class="`${(filter.orderBy=='ttl'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='ttl'?'':'rotate')}`" />
            </th>
            <th width="45"  v-if="$store.state.localStorage.user['Order_Vis_Hist1']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>H1</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='H1'" :class="`${(filter.orderBy=='H1'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='H1'?'':'rotate')}`" />
            </th>
            <th width="45"  v-if="$store.state.localStorage.user['Order_Vis_Hist2']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>H2</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='H2'" :class="`${(filter.orderBy=='H2'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='H2'?'':'rotate')}`" />
            </th>
            <th width="45"  v-if="$store.state.localStorage.user['Order_Vis_Hist3']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>H3</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='H3'" :class="`${(filter.orderBy=='H3'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='H3'?'':'rotate')}`" />
            </th>
            <th width="45"  v-if="$store.state.localStorage.user['Order_Vis_Hist4']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>H4</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='H4'" :class="`${(filter.orderBy=='H4'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='H4'?'':'rotate')}`" />
            </th>
            <th width="45"  v-if="$store.state.localStorage.user['Order_Vis_Hist5']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>H5</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='H5'" :class="`${(filter.orderBy=='H5'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='H5'?'':'rotate')}`" />
            </th>
            <th width="45"  v-if="$store.state.localStorage.user['Order_Vis_Hist6']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>H6</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='H6'" :class="`${(filter.orderBy=='H6'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='H6'?'':'rotate')}`" />
            </th>
            <th width="45"  v-if="$store.state.localStorage.user['Order_Vis_Hist7']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>H7</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='H7'" :class="`${(filter.orderBy=='H7'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='H7'?'':'rotate')}`" />
            </th>
            <th width="45"  v-if="$store.state.localStorage.user['Order_Vis_Hist8']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>H8</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='H8'" :class="`${(filter.orderBy=='H8'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='H8'?'':'rotate')}`" />
            </th>
            <th width="45"  v-if="$store.state.localStorage.user['Order_Vis_Hist9']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>H9</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='H9'" :class="`${(filter.orderBy=='H9'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='H9'?'':'rotate')}`" />
            </th>
            <th width="45"  v-if="$store.state.localStorage.user['Order_Vis_Hist10']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>H10</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='H10'" :class="`${(filter.orderBy=='H10'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='H10'?'':'rotate')}`" />
            </th>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_GlutenFree']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>Gluten Free</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='glutenfree'" :class="`${(filter.orderBy=='glutenfree'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='glutenfree'?'':'rotate')}`" />
            </th>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_Vegetarian']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>Vegetarian</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='vegetarian'" :class="`${(filter.orderBy=='vegetarian'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='vegetarian'?'':'rotate')}`" />
            </th>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_Vegan']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>Vegan</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='vegan'" :class="`${(filter.orderBy=='vegan'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='vegan'?'':'rotate')}`" />
            </th>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_Lenten']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>Lenten</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='lenten'" :class="`${(filter.orderBy=='lenten'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='lenten'?'':'rotate')}`" />
            </th>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_Pork']" class="text-white" style="background-color: var(--bs-gray-700);">
              <span><small>No Pork</small></span>
              <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc');  filter.orderBy='pork'" :class="`${(filter.orderBy=='pork'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='pork'?'':'rotate')}`" />
            </th>
          </tr>
        <tr>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_Image']" class="text-white" style="background-color: var(--bs-gray-500);">
            </th>
            <th width="70" v-if="$store.state.localStorage.user['Order_Vis_ItemNo']" class="text-white" style="background-color: var(--bs-gray-500);">
              <input tabindex="5" autocomplete="off" autosuggest="off" @keydown="moveToInput"  placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['itemno']" />
            </th>
            <th width="160" class="text-white" style="background-color: var(--bs-gray-500);">
              <input tabindex="10" autocomplete="off" autosuggest="off" @keydown="moveToInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['itmdesc']" />
            </th>
            <th width="100" v-if="$store.state.localStorage.user['Order_Vis_PriceCode']" class="text-white" style="background-color: var(--bs-gray-500);">
              <input tabindex="20" autocomplete="off" autosuggest="off" @keydown="moveToInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['pricecode']" />
            </th>
            <th width="100" class="text-white text-center align-middle" style="background-color: var(--bs-gray-500);">
                <small class="text-black">Ordered</small> 
                <div class="d-inline-block ms-1 form-check form-switch text-center">
                  <input tabindex="30" type="checkbox" v-model="search['qty']" class="form-check-input float-none">
                </div>
            </th>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_Healthy']" class="text-white text-center" style="background-color: var(--bs-gray-500);">
              <div class="form-check form-switch">
                <input tabindex="40" type="checkbox" v-model="search['healthy']" @keydown="moveToInput" class="form-check-input float-none">
              </div>
            </th>
            <th width="65" v-if="$store.state.localStorage.user['Order_Vis_MSRP']&&$store.state.localStorage.user['Admin']" class="text-white" style="background-color: var(--bs-gray-500);">
              <input tabindex="50" autocomplete="off" autosuggest="off" @keydown="moveToInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['msrp']" />
            </th>
            <th width="120" v-if="$store.state.localStorage.user['Order_Vis_UpcCode']" class="text-white" style="background-color: var(--bs-gray-500);">
              <input tabindex="60" autocomplete="off" autosuggest="off" @keyup="moveToInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['UPC']" />
            </th>
            <th width="90" class="text-white" style="background-color: var(--bs-gray-500);" v-if="$store.state.localStorage.user['Menu Change']&&$store.state.localStorage.user['Order_Vis_RemoveFromMenu']">
              <div class="form-check form-switch">
                <input tabindex="70" type="checkbox" v-model="search['removed']" class="form-check-input float-none">
              </div>
            </th>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_UserDef']" class="text-white" style="background-color: var(--bs-gray-500);">
              <input tabindex="80" autocomplete="off" autosuggest="off" @keydown="moveToInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['userdef']" />
            </th>
            <th width="80"  v-if="$store.state.localStorage.user['Order_Vis_TraySize']" class="text-white" style="background-color: var(--bs-gray-500);">
              <input tabindex="90" autocomplete="off" autosuggest="off" @keydown="moveToInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['tray']" />
            </th>
            <th width="80"  v-if="$store.state.localStorage.user['Order_Vis_ShelfLife']" style="background-color: var(--bs-gray-500);">
              <input tabindex="100" autocomplete="off" autosuggest="off" @keydown="moveToInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['life']" />
            </th>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_PullCode']" class="text-white" style="background-color: var(--bs-gray-500);">
              <input tabindex="110" autocomplete="off" autosuggest="off" @keydown="moveToInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['pullcode']" />
            </th>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_MSRPCode']&&$store.state.localStorage.user['Admin']" class="text-white" style="background-color: var(--bs-gray-500);">
              <input tabindex="120" autocomplete="off" autosuggest="off" @keydown="moveToInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['msrpcode']" />
            </th>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_BaseCost']&&$store.state.localStorage.user['Admin']" class="text-white" style="background-color: var(--bs-gray-500);">
              <input tabindex="130" autocomplete="off" autosuggest="off" @keydown="moveToInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['basecost']" />
            </th>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_Extended']&&$store.state.localStorage.user['Admin']" class="text-white" style="background-color: var(--bs-gray-500);">
              <input tabindex="140" autocomplete="off" autosuggest="off" @keydown="moveToInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['EXTENDED']" />
            </th>
            <th width="50"  v-if="$store.state.localStorage.user['Order_Vis_HistTot']" class="text-white" style="background-color: var(--bs-gray-500);">
              <input tabindex="150" autocomplete="off" autosuggest="off" @keydown="moveToInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['ttl']" />
            </th>
            <th width="45"  v-if="$store.state.localStorage.user['Order_Vis_Hist1']" class="text-white" style="background-color: var(--bs-gray-500);">
              <input tabindex="160" autocomplete="off" autosuggest="off" @keydown="moveToInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['H1']" />
            </th>
            <th width="45"  v-if="$store.state.localStorage.user['Order_Vis_Hist2']" class="text-white" style="background-color: var(--bs-gray-500);">
              <input tabindex="170" autocomplete="off" autosuggest="off" @keydown="moveToInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['H2']" />
            </th>
            <th width="45"  v-if="$store.state.localStorage.user['Order_Vis_Hist3']" class="text-white" style="background-color: var(--bs-gray-500);">
              <input tabindex="180" autocomplete="off" autosuggest="off" @keydown="moveToInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['H3']" />
            </th>
            <th width="45"  v-if="$store.state.localStorage.user['Order_Vis_Hist4']" class="text-white" style="background-color: var(--bs-gray-500);">
              <input tabindex="190" autocomplete="off" autosuggest="off" @keydown="moveToInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['H4']" />
            </th>
            <th width="45"   v-if="$store.state.localStorage.user['Order_Vis_Hist5']" class="text-white" style="background-color: var(--bs-gray-500);">
              <input tabindex="200" autocomplete="off" autosuggest="off" @keydown="moveToInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['H5']" />
            </th>
            <th width="45"  v-if="$store.state.localStorage.user['Order_Vis_Hist6']" class="text-white" style="background-color: var(--bs-gray-500);">
              <input tabindex="210" autocomplete="off" autosuggest="off" @keydown="moveToInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['H6']" />
            </th>
            <th width="45"  v-if="$store.state.localStorage.user['Order_Vis_Hist7']" class="text-white" style="background-color: var(--bs-gray-500);">
              <input tabindex="220" autocomplete="off" autosuggest="off" @keydown="moveToInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['H7']" />
            </th>
            <th width="45"  v-if="$store.state.localStorage.user['Order_Vis_Hist8']" class="text-white" style="background-color: var(--bs-gray-500);">
              <input tabindex="230" autocomplete="off" autosuggest="off" @keydown="moveToInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['H8']" />
            </th>
            <th width="45"  v-if="$store.state.localStorage.user['Order_Vis_Hist9']" class="text-white" style="background-color: var(--bs-gray-500);">
              <input tabindex="240" autocomplete="off" autosuggest="off" @keydown="moveToInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['H9']" />
            </th>
            <th width="45"  v-if="$store.state.localStorage.user['Order_Vis_Hist10']" class="text-white" style="background-color: var(--bs-gray-500);">
              <input tabindex="250"  autocomplete="off" autosuggest="off" @keydown="moveToInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['H10']" />
            </th>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_GlutenFree']" class="text-white text-center" style="background-color: var(--bs-gray-500);">
              <div class="form-check form-switch">
                <input tabindex="260" type="checkbox" v-model="search['glutenfree']" class="form-check-input float-none">
              </div>
            </th>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_Vegetarian']" class="text-white text-center" style="background-color: var(--bs-gray-500);">
              <div class="form-check form-switch">
                <input tabindex="280" type="checkbox" v-model="search['vegetarian']" class="form-check-input float-none">
              </div>
            </th>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_Vegan']" class="text-white text-center" style="background-color: var(--bs-gray-500);">
              <div class="form-check form-switch">
                <input tabindex="290" type="checkbox" v-model="search['vegan']" class="form-check-input float-none">
              </div>
            </th>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_Lenten']" class="text-white text-center" style="background-color: var(--bs-gray-500);">
              <div class="form-check form-switch">
                <input tabindex="300" type="checkbox" v-model="search['lenten']" class="form-check-input float-none">
              </div>
            </th>
            <th width="80" v-if="$store.state.localStorage.user['Order_Vis_Pork']" class="text-white text-center" style="background-color: var(--bs-gray-500);">
              <div class="form-check form-switch">
                <input tabindex="300" type="checkbox" v-model="search['pork']" class="form-check-input float-none">
              </div>
            </th>
          </tr>
    </thead>
</template>
  <script>

  import Header from '@/components/Orders/Header.vue';
    import PageContent from '@/components/PageContent.vue';
    import NotReady from '@/components/NotReady.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import Table from '@/components/Table/Table.vue';
    import TableRow from '@/components/Table/TableRow.vue';
    import History from '@/components/Orders/History.vue';
    import VueSimpleAlert from "vue3-simple-alert";
  export default {
    name: 'TableHead',
    props:['filter','search'],
    components: {PageContent, PageTitle, Table, TableRow, NotReady, Header,History},
    async mounted(){
    },
    methods:{
      moveToInput(e){
        let matchingCells;
        switch(e.key){
            case 'Tab':
            case 'ArrowDown':
            // case 'Enter':
                matchingCells = ($(e.target).parent().nextAll().filter(function(){
                  return $(this).find('input[type="text"]').length > 0;
                }));
                if(matchingCells.length>0){
                  $(matchingCells[0]).find('input[type="text"]').focus();
                }
                else{
                  $(e.target).parents('.table-header-parent').next().find('input.qty-field:visible').first().focus();
                }
                e.preventDefault();
            break;
            case 'ArrowUp':
                matchingCells = ($(e.target).parent().prevAll().filter(function(){
                  return $(this).find('input[type="text"]').length > 0;
                }));
                if(matchingCells.length>0){
                  $(matchingCells[0]).find('input[type="text"]').focus();
                }
                
                e.preventDefault();
            break;
        }
        
      }
      //  filterInput(){

      //  }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
    
  </style>
  