<template>
      <br>
      <ScrollingTable :style="`position:sticky; top: 0; left: 0`" :scroll="true">
        <template v-slot:table-header>
            <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                <thead class="position-relative">
                    <tr>
                      <th class="text-white" style="background-color: var(--bs-gray-700);"></th>
                      <th class="text-white" style="background-color: var(--bs-gray-700); width: 140px;"><small>Monday</small></th>
                      <th class="text-white" style="background-color: var(--bs-gray-700); width: 140px;"><small>Tuesday</small></th>
                      <th class="text-white" style="background-color: var(--bs-gray-700); width: 140px;"><small>Wednesday</small></th>
                      <th class="text-white" style="background-color: var(--bs-gray-700); width: 140px;"><small>Thursday</small></th>
                      <th class="text-white" style="background-color: var(--bs-gray-700); width: 140px;"><small>Friday</small></th>
                      <th class="text-white" style="background-color: var(--bs-gray-700); width: 140px;" v-if="canOrderSaturday"><small>Saturday</small></th>
                      <th class="text-white" style="background-color: var(--bs-gray-700); width: 140px;"><small>Total</small></th>
                    </tr>
                </thead>
            </table>
        </template>
        <template v-slot:table-body>
            <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                <tbody>
                  <tr v-if="!type||type=='LOCATION'">
                      <td></td>
                      <td style="width: 140px;">{{ _.sumBy(rows, d=>+d['monday_count']) }}</td>
                      <td style="width: 140px;">{{ _.sumBy(rows, d=>+d['tuesday_count']) }}</td>
                      <td style="width: 140px;">{{ _.sumBy(rows, d=>+d['wednesday_count']) }}</td>
                      <td style="width: 140px;">{{ _.sumBy(rows, d=>+d['thursday_count']) }}</td>
                      <td style="width: 140px;">{{ _.sumBy(rows, d=>+d['friday_count']) }}</td>
                      <td v-if="canOrderSaturday" style="width: 140px;">{{ _.sumBy(rows, d=>+d['saturday_count']) }}</td>
                      <td style="width: 140px;">{{ _.sumBy(rows, d=>+d['total']) }}</td>
                  </tr>
                  <tr v-else>
                    <td></td>
                    <td style="width: 140px;">{{ _.sumBy(rows, d=>+d['Monday']) }}</td>
                    <td style="width: 140px;">{{ _.sumBy(rows, d=>+d['Tuesday']) }}</td>
                    <td style="width: 140px;">{{ _.sumBy(rows, d=>+d['Wednesday']) }}</td>
                    <td style="width: 140px;">{{ _.sumBy(rows, d=>+d['Thursday']) }}</td>
                    <td style="width: 140px;">{{ _.sumBy(rows, d=>+d['Friday']) }}</td>
                    <td v-if="canOrderSaturday" style="width: 140px;">{{ _.sumBy(rows, d=>+d['Saturday']) }}</td>
                    <td style="width: 140px;">{{ _.sumBy(rows, d=>+d['TOTAL']) }}</td>
                  </tr>
                </tbody>
            </table>
        </template>
    </ScrollingTable>
</template>
  <script>
    import Header from '@/components/Orders/Header.vue';
    import PageContent from '@/components/PageContent.vue';
    import NotReady from '@/components/NotReady.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import ScrollingTable from '@/components/Table/ScrollingTable.vue';

  export default {
    name: 'History',
    components: {PageContent, PageTitle, NotReady, Header, ScrollingTable},
    props: ['rows','type','canOrderSaturday'],
    data(){
        return {
          _:_,
        }
    },
    async mounted(){
    },
  }
  </script>
 
  